.page-recap {
  width: 100%;
  max-width: 1500px;
  margin: auto;
  height: fit-content;

  p.empty {
    font-family: $primary-font;
    font-size: 1.5rem;
    width: fit-content;
    margin: 2rem auto 0;
  }

}

section.recap_commande {
  width: 90%;
  height: auto;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 2rem;

  article {
    margin: 0 0.5rem;
    padding: 1rem;
    border-radius: 0.2rem;
    box-shadow: 0 0.2rem 0.5rem 0.1rem rgb(190, 190, 190);

    h2 {
      font-family: $primary-font;
    }
  }

  article.recap_panier {
    width: 68%;
    min-height: 60vh;

    h2 {
      margin-left: 4rem;
    }

    .list-items{
      height: fit-content;
      max-height: 45vh;
      overflow-y: auto;
    }
    .item_panier {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      gap: 1rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid rgb(198, 198, 198);

      .image {
        width: 8rem;
        min-width: 128px;
        height: 8rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .infos-produit {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-top: 1rem;

        p {
          font-family: $secondary-font;
          font-weight: bold;



          &.montant-produit {
            -webkit-text-stroke: 1px black;
            width: fit-content;
            margin-right: 1rem;
            letter-spacing: 0.2rem;
          }
        }

        .select-quantity {
          width: 6rem;
          display: flex;
          justify-content: space-evenly;

          input {
            width: 1.5rem;
            text-align: center;
            border: none;

            &:focus {
              outline: none;
            }
          }

          button {
            width: 100%;
            border: none;
            margin: 0.5rem;
            background-color: white;
            border-radius: 0.2rem;
            cursor: pointer;
            font-size: 2rem;
            // font-weight: bold;
            font-family: $secondary-font;

            &:hover {
              box-shadow: 0 0 0.3rem rgb(208, 208, 208);
            }
          }
        }

        .icon-delete {
          position: absolute;
          right: 0;
          top: 0;
          background: $pictoDelete;
          width: 30px;
          height: 30px;
          background-size: cover;
          cursor: pointer;

          &:hover {
            transition: 0.3s;
            transform: scale(1.1);
          }
        }
      }


      p.total_item {
        font-family: $secondary-font;
        font-weight: bold;
        width: 15%;
        min-width: 125px;
        text-align: end;
        font-size: 1.2rem;
      }

      .icon-delete {
        background: $pictoDelete;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        min-width: 30px;
        margin: 0 1rem;
        cursor: pointer;

        &:hover {
          transition: 0.3s;
          transform: scale(1.1);
        }
      }
    }

    div.total_panier {
      width: 100%;
      margin: 1rem auto;
      padding-right: 3rem;
      padding-top: 1.5rem;
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid gray;

      h3 {
        font-family: $primary-font;
        margin: auto 2rem;
      }

      p {
        font-family: $secondary-font;
        font-weight: bold;
        width: 7rem;
        text-align: end;
        -webkit-text-stroke: 1px black;
        letter-spacing: 0.2rem;
        font-size: 1.3rem;
        width: 15%;
        min-width: 125px;

      }
    }
  }

  .recap_reserver {
    width: 30%;
    min-width: 355px;
    min-height: 60vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin: 1rem auto;
      height: fit-content;
    }

    p {
      width: 80%;
      font-family: $primary-font;
      font-size: 1rem;
      text-align: center;
      margin: 0 auto 1rem;
    }

    .hidden {
      height: 0;
      margin: 0;
      opacity: 0;
      transition: all 0.5s ease;
    }
    .error-message {
      width: 90%;
      margin: 1.5rem auto 0;
      height: fit-content;
      color: rgb(190, 22, 22);
      transition: all 0.5s ease;

    }

  

    .time-buttons {
      margin: 1rem 0 0;
      width: 100%;
      display: flex;
      gap: 2rem;
      justify-content: center;

      .time {
        min-width: 142px;
        font-weight: bold;
        padding: 1rem;
        border-radius: 1rem;
        font-family: $secondary-font;
        font-weight: bold;
        letter-spacing: 0.1rem;
        border: none;
        color: white;
        background: #634236;
        box-shadow: 0 5px 10px #634236ab;
        cursor: pointer;

        &:disabled {
          background: #bbb7b6;
          box-shadow: 0 5px 10px #bbb7b6;
          cursor: not-allowed;
        }

        &.selected {
          transition: all 0.2s ease;
          background: #9f7b6c;
          box-shadow: 0 5px 10px #9f7b6c;

        }
      }
    }

    .reserver-button {
      width: 85%;
      min-width: 315px;
      font-weight: bold;
      font-size: 1.1rem;
      letter-spacing: 0.2rem;
      font-family: $primary-font;
      padding: 0.9rem;
      border-radius: 1rem;
      border: none;
      margin-top: 1.5rem;
      color: rgb(255, 255, 255);
      background: #634236;
      box-shadow: 0 2px 10px #bfbfbf;
      cursor: pointer;
      transition: all 0.5s ease;


      &:hover{
        color: #D5B298;
        transition: all 0.3s ease;
        box-shadow: 0 5px 10px #D5B298;
    }
    }
    .acceptCgv{
      text-align: start;
      margin-top: 1rem;
      a{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.empty {
  text-align: center;
  height: 50vh;
}

@media screen and (max-width: 1000px) {
  .page-recap {

    section.recap_commande {
      flex-direction: column-reverse;

      align-items: center;
      width: 100%;
      height: fit-content;

      article.recap_panier {
        width: 100%;
        height: fit-content;

        .list-items{
          max-height: 40vh;
        }

        div.item_panier {
          // width: 100%;


          .buttons-quantity {
            width: 20%;

            input {
              font-family: $secondary-font;
              font-size: 1.1rem;
              font-weight: bold;
              border: none;
              text-align: center;
              width: 1.5rem;
              height: 4vh;
            }
          }
        }
      }

      .recap_reserver {
        width: 100%;
        height: fit-content;

        .reserver-button{
          width: fit-content;
        }

      }
    }
  }
}