.page-inscription {
    
    ::placeholder {
        color: rgb(0, 0, 0);
    }

    section {
        width: 70%;
        height: fit-content;
        margin: 2em auto;
        padding: 2em;
        background: white;
        box-shadow: 0px 0px 45px 6px rgba(0, 0, 0, 0.25);
        font-size: 1rem;
        font-weight: bold;
        
        h2 {
            font-family: $primary-font;
            text-align: center;
            letter-spacing: .2vw;
            margin-bottom: 2em;
        }
        
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 90%;
            margin: auto;
            font-family: $secondary-font;
            
            input {
                width: 40%;
                margin: 1rem;
                height: 3rem;
                padding-left: 2em;
                font-family: $secondary-font;
                font-size: 1rem;
                font-weight: bold;
                border-radius: 0.2rem;
                border: 1px solid #F2F2F2;
                background: #F2F2F2;
                box-shadow: 0 0 0.5rem rgb(182, 182, 182);
                
                
                
                &[type=checkbox] {
                    width: auto;
                    height: auto;
                    cursor: pointer;
                }
                
                &.inputError {
                    border: 1px solid rgb(193, 39, 39);
                background: #f4ebeb;

                box-shadow: 0 0 0.5rem rgba(182, 105, 105, 0.262);

                    &::placeholder {
                        color: rgb(193, 39, 39);
                    }
                }
            }
            
            div.check-news-btn {
                width: 100%;
                margin: auto;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                align-items: center;

                div.btn {
                    width: 40%;
                    margin: auto;
                    display: flex;
                    justify-content: center;

                    button {
                        border-radius: 2em;
                        padding: 0.5rem 1rem;
                        border: none;
                        margin: auto 1em;
                        font-family: $primary-font;
                        letter-spacing: 0.1rem;
                        cursor: pointer;


                        &:nth-child(1) {
                            color: white;
                            background: #634236;
                            box-shadow: 0 5px 10px #634236;
                        }

                        &:nth-child(2) {
                            font-weight: bold;
                            color: #35221c;
                            background: #C8A48A;
                            box-shadow: 0 5px 10px #C8A48A;
                        }
                    }
                }
            }


            textarea {
                width: 40%;
                margin: 1rem;
                height: 3rem;
                padding-left: 2em;
                padding-top: 0.9em;
                font-family: $secondary-font;
                font-size: 1rem;
                font-weight: bold;
                background: #F2F2F2;
                border: none;
                resize: none;
                box-shadow: 0 0 0.5rem rgb(182, 182, 182);

            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .page-inscription{

        section {
            width: 90%;
            height: fit-content;
        margin: 2em auto;
        padding: 2em;
        background: white;
        box-shadow: 0px 0px 45px 6px rgba(0, 0, 0, 0.25);
        font-size: 1rem;
        font-weight: bold;

        h2 {
            font-family: $primary-font;
            text-align: center;
            letter-spacing: .2vw;
            margin-bottom: 2em;
        }

        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin: auto;
            font-family: $secondary-font;

            input {
                width: 90%;
                margin: 1rem;
                height: 3rem;
                padding-left: 2em;
                font-family: $secondary-font;
                font-size: 1rem;
                font-weight: bold;
                border: none;
                box-shadow: 0 0 0.5rem rgb(182, 182, 182);


                &[type=checkbox] {
                    width: auto;
                    height: auto;
                }


            }

            div.check-news-btn {
                width: 100%;
                margin: auto;
                margin-bottom: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                div.news {
                    display: flex;
                    flex-wrap: wrap;
                }

                div.btn {
                    width: 70%;
                    min-width: 272px;
                    padding-top: 1rem;
                    margin: auto;
                    display: flex;
                    justify-content: center;

                    button {
                        height: 2.5em;
                        border-radius: 2em;
                        border: none;
                        margin: auto 1em;
                        font-family: $secondary-font;
                        
                        &:nth-child(1) {
                            font-weight: bold;
                            color: white;
                            background: #634236;
                            box-shadow: 0 5px 10px #634236;
                        }

                        &:nth-child(2) {
                            font-weight: bold;
                            color: #35221c;
                            background: #C8A48A;
                            box-shadow: 0 5px 10px #C8A48A;
                        }
                    }
                }
            }


            textarea {
                width: 90%;
                margin: 1rem;
                height: 3rem;
                padding-left: 2em;
                padding-top: 0.9em;
                font-family: $secondary-font;
                font-size: 1rem;
                font-weight: bold;
                background: #F2F2F2;
                border: none;
                resize: none;
                box-shadow: 0 0 0.5rem rgb(182, 182, 182);

            }
        }
    }

}
}
@media screen and (max-width: 700px) {
    .page-inscription{

        section {
            width: 100%;
            padding: 1rem;
        }
        
    }
}