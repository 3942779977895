.admin-main {
    display: flex;
    width: 90%;
    margin: auto;

    .aside-admin {
        width: 25vw;
        min-width: 250px;
        max-width: 330px;
        background-color: #634236;
        height: 70vh;
        margin-right: 3rem;
        ul {
            width: 100%;
            height: 100%;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            box-shadow: 0 0 2rem rgb(99, 99, 99);
            li{
                position: relative;
                a{
                    font-family: $primary-font;
                    letter-spacing: 0.1rem;
                    font-size: 1.2rem;
                    text-decoration: none;
                    color: white;
                }

            .numberMessages {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(100%, -40%);
                background-color: #9B3EAA;
                color: white;
                height: 24px;
                width: 24px;
                border-radius: 10rem;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $secondary-font;
                font-weight: bold;

            }

            }
        }

    }
}