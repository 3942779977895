@import './variables.scss';

.verif {
    min-height: 50vh;
}

.verifSection {
    width: 90%;
    height: 100%;
    margin: 2em auto;
    padding: 2em;
    background: white;
    box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.15);
    font-size: 1rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-family: $primary-font;
        text-align: center;
        letter-spacing: .2vw;
        margin-bottom: 2em;
    }


    p {
        max-width: 360px;
    }

}


.updateForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 360px;

    p {
        text-align: center;
        color: rgb(189, 59, 59);
    }

    label {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-family: $primary-font;

        input {
            height: 2rem;
            width: 100%;
            margin: 0.2rem;
            margin-bottom: 1rem;
            padding-left: 2em;
            font-family: $secondary-font;
            font-size: 1rem;
            font-weight: bold;
            background: #F2F2F2;
            border: 1px solid #F2F2F2;
            box-shadow: 0 0 0.5rem rgb(182, 182, 182);
        }


    }

    button {
        font-family: $primary-font;
        font-weight: bold;
        height: 2.5rem;
        
        border: none;
        cursor: pointer;
        border-radius: 0.2rem;
        color: rgb(226, 226, 226);
        background-color: #634236;
        box-shadow: 0 0.2rem 0.7rem rgb(159, 159, 159);
    }
}