.produit-compact {
    width: 45%;
    min-width:620px;
    min-height: 15rem;
    margin: 1rem 1vw;
    display: flex;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 2rem rgb(201, 201, 201);
    z-index: 2;
    transition: width 0.5s ease;

    .card-img {
        width: 60%;
        min-width: 205px;
        margin: 1rem;
        height: auto;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: -0.7rem 0.2rem 1rem rgb(201, 201, 201);
        }

    }
    .produits-infos{
        width: 100%;
        height: 100%;
        margin: 1rem 0;
        padding: 1rem 1rem 1rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        h3{
            width: 100%;
            min-width: 357px;
            font-size: 1.1rem;
            text-align: center;
            font-family: $primary-font;
        }
        p{
            width: 100%;
            font-family: $primary-font;
            font-size: 1rem;
            margin: 1rem 0;
            height: auto;
            min-height: 20%;
        }
        h4{
            font-family: $primary-font;
            text-align: center;
        }
        button{
            width: 60%;
            margin: auto;
            border: none;
            padding: 0.7rem 0.5rem;
            font-family: $primary-font;
            background-color: #634236;
                color: white;
            box-shadow: 0 0 0.5rem rgb(157, 157, 157);
            cursor: pointer;
            border-radius: 0.4rem;
            transition: all 0.4s ease;

            &:hover{
                color: #D5B298;
                transition: all 0.3s ease;
                box-shadow: 0 5px 10px #D5B298;
            }
        }
    }
}

@media screen and (max-width: 620px) {

    .produit-compact {
        width: 100%;
        min-width: 200px;
        height: fit-content;
        margin: 0.8rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        
    
        .card-img {
            width: 90%;
            
            img{
                height: 100%;
                max-height: 400px;   
            }
    
        }
        .produits-infos{
            margin-top: 0.2rem;           
            padding: 0.5rem;

            h3{
                min-width: 0;
            }

            h4{
                margin-bottom: 1.2rem;
            }
            button{
                background-color: #634236;
                color: white;
            }
        }
          
        }
    
    
}