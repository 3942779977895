@import '../variables.scss';

.messagesAdmin {
    width: 100%;
    min-width: 1140px;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    gap: 2rem;
    box-shadow: 0 0 1.4rem rgb(171, 171, 171);

    h2 {
        width: 100%;
        text-align: center;
        font-family: $primary-font;
        letter-spacing: 0.1rem;
        font-weight: normal;
    }

}

.indexConversations {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    gap: 1rem;
}