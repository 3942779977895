div.fond-modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.251);
    animation: bgdetails1 0.5s;

    div.modal-connexion {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24%;
        min-width: 340px;
        max-width: 400px;
        height: 30%;
        min-height: 250px;
        background-color: rgb(247, 247, 247);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 1.5rem;
        padding-bottom: 0.5rem;
        border-radius: 1rem;
        box-shadow: 0 0 3rem rgba(0, 0, 0, 0.304);
        animation: popupdetails 0.3s;

        h3 {
            font-family: $primary-font;
            letter-spacing: 0.1rem;
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .forgotPass {
                font-family: $primary-font;
                align-self: flex-end;
                font-size: 0.9rem;
                color: rgb(117, 117, 117);
            }
        }

        input {
            height: 2rem;
            width: 100%;
            margin: 0.2rem;
            margin-bottom: 1rem;
            padding-left: 2em;
            font-family: $secondary-font;
            font-size: 1rem;
            font-weight: bold;
            background: #F2F2F2;
            border: none;
            box-shadow: 0 0 0.5rem rgb(182, 182, 182);

            &:last-of-type {
                margin-bottom: 0.5rem;
            }
        }

        div.btns {
            width: 100%;
            margin: 1rem auto;
            display: flex;
            justify-content: center;

            button {
                width: 8em;
                height: 2.5em;
                border-radius: 2em;
                border: none;
                margin: auto 1em;
                cursor: pointer;
                font-family: $secondary-font;

                &:nth-child(1) {
                    font-weight: bold;
                    color: white;
                    background: #634236;
                    box-shadow: 0 5px 10px #634236;
                }

                &:nth-child(2) {
                    font-weight: bold;
                    color: #35221c;
                    background: #C8A48A;
                    box-shadow: 0 5px 10px #C8A48A;
                }
            }
        }

        .error {
            color: rgb(207, 65, 13);
            font-size: 0.9rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        .success {
            color: rgb(36, 176, 74);
            font-size: 0.9rem;
            font-weight: 500;
            text-align: center;
            margin-bottom: 0.5rem;
        }

        .inscription {
            font-family: $primary-font;
            width: fit-content;
            font-size: 14.5px;            
            color: rgb(117, 117, 117);
        }
    }
}