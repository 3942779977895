.bandeau{
    width: 100%;
    height: 30vh;
    margin: 4vh 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 4em rgba(0, 0, 0, 0.171);
    article{
        width: 30%;
        font-family: $primary-font;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: space-evenly;
        text-align: center;
        p{
            margin: 0;
        }
        &:nth-child(3){
            p{
                text-align: justify;
                padding: 1em 6.5em;
            }
        }
        .icon{
            margin-top: 1em;
            width: 60px;
            height: 60px;
            align-self: center;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            &.credit-card{
                background-image: $pictoCreditCard;
            }
            &.shop{
                background-image: $pictoShop;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .bandeau{
        width: 100%;
        height: auto;
        margin: 4vh 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 0 4em rgba(0, 0, 0, 0.171);
        article{
            width: 100%;
            font-family: $primary-font;
            text-align: center;
            margin: 1rem 0;
            padding-bottom: 3rem;
            border-bottom: 1px solid rgb(230, 230, 230);
            h4{
                font-size: 1.2rem;
            }
            p{
                font-size: 1rem;
            }
            &:nth-child(3){
                p{
                    text-align: center;
                    padding: 1rem 3rem;
                    
                }
                border: none;
            }
        }
    }
}