@import "../variables.scss";

.modalDetailsCommande {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 50vw;
    max-width: 700px;
    min-width: 550px;
    height: 50vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2rem;
    box-shadow: 0 1rem 2rem rgb(183, 183, 183);
}

.infosClients {
    p {
        font-family: $primary-font;
    }
}

.statutsCommande {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
}

.labels {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-family: $primary-font;
    font-size: 1.2rem;
}

.statutsDate {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    font-weight: bold;
    font-family: $secondary-font;
}

.detailsPanier {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 320px;
    overflow-y: auto;

    h5 {
        font-size: 1.3rem;
        font-family: $primary-font;
        margin-bottom: 1rem;
    }

}

.details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

    p {
        font-family: $primary-font;
    }

}

.image {
    width: 80px;
    height: 80px;

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.totalCommande {
    width: 80%;
    display: flex;
    justify-content: flex-end;
    gap: 3rem;
    font-family: $primary-font;
}

.buttonDiv {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
        font-family: $primary-font;
        font-weight: bold;
        padding: 0.4rem 1.5rem;
        border: none;
        cursor: pointer;
        border-radius: 1rem;
        box-shadow: 0 0.2rem 0.7rem #97665495;

        color: rgb(255, 255, 255);
        background-color: #634236;
    }
}

.buttonBrown {
    width: 170px;
    font-family: $primary-font;
    font-weight: bold;
    padding: 0.4rem 1.5rem;
    border: none;
    cursor: pointer;
    border-radius: 0.2rem;
    box-shadow: 0 0.2rem 0.7rem #97665495;

    color: rgb(255, 255, 255);
    background-color: #634236;

    &[disabled]{
    background-color: #947d74;
    cursor: not-allowed;

    }
}