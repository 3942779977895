.item-produit {
    width: 95%;
    height: 3rem;
    margin: 0 auto 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .image{
        width: 60px;
        height: 50px;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    p {
        font-family: $primary-font;
        text-align: start;
        width: 14%;
        padding-left: 1rem;
        text-align: justify;
    }

    .date {
        width: 10%;
        min-width: 90px;
    }

    .titre{
        text-align: center;
        width: 19%;
    }

    .categorie{
        text-align: center;
    }

    .actions{
        display: flex;
        gap: 0.3rem;
        align-items: center;
    }

    .description{
        width: 25%;
    }
    .prix{
        width: 10%;
    }
    .statut {
        width: 7%;
    }
    &.darker {
        background-color: #a46a5645;

    }

    .admin-icon-edit {
        background: $pictoEdit;
        width: 25px;
        height: 25px;
        cursor: pointer;
        &:hover{
            transition: 0.3s;
            transform: scale(1.1);
        }
    }

    .icon-delete {
        background: $pictoDelete;
        background-size: cover;
        width: 30px;
        height: 30px;;
        cursor: pointer;
        &:hover{
            transition: 0.3s;
            transform: scale(1.1);
        }
    }
}