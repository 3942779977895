footer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
    margin: auto;
    padding-top: 2vw;
    padding-bottom: 2vw;
    position: sticky;
    box-shadow: 0 2em 4em;
    background: white;

    .image{
        max-width: 8rem;

        img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        }
    }
    p{
        font-family: $primary-font;
        width: fit-content;
    }

    .horaires{
        width: fit-content;
        h4{
            font-family: $primary-font;
            margin-bottom: 0.5rem;
        }
        .jour{
            gap: 0.5rem;
            width: 100%;
            display: flex;
            p{
                min-width: 94px;
            }
        }
    }
    .cgv{
        display: flex;
        flex-direction: column;
        gap: 1rem;

        p{
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
@media screen and (max-width: 950px) {
    footer{
       gap: 1rem;
        .horaires{
            display: none;
        }
    }
}

@media screen and (max-width: 540px) {
    footer{
       gap: 1rem;
        .coordonnees{
            display: none;
        }
        .cgv{
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}