@import '../variables.scss';


.itemConversation {
    position: relative;
    width: 100%;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    box-shadow: 0 0 0.7rem rgb(219, 219, 219);
    font-family: $primary-font;
    padding: 0.5rem;
    cursor: pointer;
    
    h3{
        width: 60%;
        text-align: center;
        margin-bottom: 0.2rem;
    }
    
    
}

.itemConversationToRead {
    position: relative;
    width: 100%;
    max-height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-family: $primary-font;
    padding: 0.5rem;
    cursor: pointer;
    background: #ECE9E6;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #FFFFFF, #f7f7f7);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #FFFFFF, #f7f7f7); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
border-radius: 5px;

    font-weight: bold;
    border-left: 5px solid #9B3EAA;
    
    h3{
        width: 60%;
        text-align: center;
        margin-bottom: 0.2rem;
    }
    
}

.dateConversation{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 100%;
    box-shadow: 0 0 0.7rem rgb(203, 203, 203);
    margin: 0.5rem;


    p{
        font-family: $primary-font;
    }

}
.apercu {
    width: 60%;
}
.iconDelete {
    position: absolute;
    top: 5px;
    right: 10px;
    background: $pictoDelete;
    background-size: cover;
    background-position: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:hover{
        transition: 0.3s;
        transform: scale(1.1);
    }
}