.item-commande{
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    &.darker {
        background-color: #a46a5645;
    }
    p {
        font-family: $primary-font;
        font-size: 1rem;
        text-align: center;
    }

    .date{
        width: 10%;
    }

    .clientName{
        width: 18%;
    }
    .details{
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        width: 30%;
        height: 100%;
        p{
            text-align: start;
        }

        .image{
            height: 100%;
            width: 4vw;
            margin: 0.5rem 0;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .montantTotal{
        width: 9%;
    }
    .validationStatut{
        width: 12%;
        text-align: center;
    }
    .icons{
        position: relative;
        width: 15%;
        display: flex;
        justify-content: space-between;
    }
    .admin-icon-edit {
        background: $pictoEdit;
        width: 25px;
        height: 25px;
        cursor: pointer;
        &:hover{
            transition: 0.3s;
            transform: scale(1.1);
        }
    }

    .icon-delete {
        background: $pictoDelete;
        background-size: cover;
        background-position: center;
        width: 30px;
        height: 30px;
        cursor: pointer;
        &:hover{
            transition: 0.3s;
            transform: scale(1.1);
        }
    }
    .button-details{
        border: none;
        font-family: $primary-font;
        cursor: pointer;
        padding: 0.4rem 1rem 0.3rem;
       background-color: #634236;
       color: white;
       border-radius: 0.7rem;
    }

}