.item-client {
    width: 90%;
    height: 3rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-family: $primary-font;
        text-align: start;
        width: 14%;
        word-break: break-all;
        padding-left: 1rem;

        &.large {
            width: 22%;
        }

        &.medium {
            width: fit-content;
        }


    }
    .adresse {
        width: 22%;
        display: flex;
        flex-wrap: wrap;
        p{
            width: 100%;
        }
    }

    .small {
        width: 7%;
        display: flex;
        justify-content: space-evenly;

    }

    &.darker {
        background-color: #a46a5645;

    }

    .admin-icon-edit {
        background: $pictoEdit;
        width: 25px;
        height: 25px;
        cursor: pointer;
        &:hover{
            transition: 0.3s;
            transform: scale(1.1);
        }
    }

    .icon-delete {
        background: $pictoDelete;
        background-size: cover;
        width: 30px;
        height: 30px;
        cursor: pointer;
        &:hover{
            transition: 0.3s;
            transform: scale(1.1);
        }
    }
}