.main-edit-close{
    width: 100%;
    height: 100%;
    padding-top: 100px;
    transition: all 0.5s ease;

}


.appBar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    padding: 1rem;
    z-index: 2;
    transition: all 0.5s ease;

    img {
        height: 100%;
        width: 100px;
        object-fit: cover;
        margin-right: 70px;
    }

    svg {
        align-self: flex-start;
        cursor: pointer;
        transition: all 0.5s ease;

    }

    
}




.add-preview-images {
    width: 100%;
    min-height: 200px;
    height: auto;
    margin: auto;

    .addImage {
        width: 100%;
        height: 100%;
        min-height: 200px;
        background-image: $pictoAddImage;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
    }

    .preview-images {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        img {
            width: 90%;
            margin: auto;
            margin-top: 1rem;

            &.mini {
                width: fit-content;
                max-width: 30%;
                margin-top: 0.5rem;
                height: 3rem;
                max-height: fit-content;
            }
        }
    }

}

.buttonDelete {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(0, -50%);
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    list-style: none;
    align-items: center;
    font-size: 1rem;
}

.chip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 1rem;
    font-family: $primary-font;
    font-weight: bold;
    margin: 1rem 0 0.5rem;
    padding: 0.3rem 1.5rem;
    box-shadow: 0 0 0.5rem rgb(190, 190, 190);
    cursor: pointer;
    transition: all 0.7s ease;
    position: relative;

    &:hover {
        transition: all 0.4s ease;
        box-shadow: 0 0 1rem rgb(200, 200, 200);

    }

    &.selected {
        color: #D5B298;
        background-color: #634236;
        border: none;
    }
}

.iconDelete {
    background: $pictoDelete;
    width: 20px;
    height: 20px;
    background-size: cover;
    cursor: pointer;
    margin-left: 10px;

}

.divider {
    width: 100%;
    height: 1px;
    margin: 2rem 0;
    position: relative;

    p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        margin: 0;
        padding: 0 1rem;
        z-index: 2;

    }
}

.flexBetween {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1rem;

        input {
            width: 90%;
            padding-left: 1.5rem;
        }

        span {
            width: 100%;
            font-size: 1rem;
            font-weight: 600;
            font-family: $primary-font;
        }
    }
}

.card-recap-produit {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: auto;
    font-family: $primary-font;
    box-shadow: 0 0 1rem rgb(237, 237, 237);
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin-bottom: 1rem;
    }

    .details-produit {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 1rem 2rem;

        h1 {
            text-align: center;
        }

        h4 {
            font-weight: 700;
        }

        .paragraphe {
            margin-top: 1rem;

            p {
                margin-top: 0;
            }

            ;
        }

        .select-options {
            margin-top: 1rem;

            .select-condi {
                display: flex;
                flex-direction: row;
                align-items: baseline;
                margin: 0.5rem 0;

                p {
                    margin-top: 0;
                    margin-left: 0.5rem;
                }

                label {
                    display: flex;
                    font-family: $secondary-font;
                    font-weight: bold;
                    cursor: pointer;
                    margin: 0 0.5rem;

                    .checkbox {
                        width: 1rem;
                        height: 1rem;
                        border-radius: 1rem;
                        margin-right: 0.5rem;
                        box-shadow: 1.58322px 3.16644px 3.16644px rgba(0, 0, 0, 0.17), inset 0px 1.95805px 3.95805px rgba(0, 0, 0, 0.25);

                        &.checked {
                            background: #D3AD92;
                        }
                    }
                }
            }
        }

        .select-quantity {
            display: flex;
            align-items: center;

            h4 {
                padding-top: 0.2rem;
            }

            #quantity {
                width: 3rem;
                margin-left: 0.7rem;
                box-shadow: 0.05rem 0.1rem 0.2rem rgb(189, 189, 189);
                cursor: pointer;
                padding: 0.5rem 0;

                p {
                    font-family: $secondary-font;
                    font-weight: bold;
                    width: auto;
                    text-align: center;
                    margin-top: 0;
                }
            }
        }

        .montants {
            margin: 1.5rem 0 2rem;
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            font-family: $secondary-font;

            h1 {
                font-size: 1.5rem;
            }

            p {
                margin-left: 5%;
                width: 30%;
                font-weight: bold;
            }
        }

        .button {
            width: 100%;
            height: 3rem;
            border-radius: 1rem;
            background-color: #D5B298;
            color: white;
            font-size: 1.5rem;
            font-weight: bold;
            border: none;
            cursor: pointer;
            transition: all 0.7s ease;

            &:hover {
                transition: all 0.4s ease;
                background-color: #634236;
            }
        }
    }
}

.inputEuro {
    position: relative;

    .currency {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-70%);
        background-color: white;
        padding: 0.5rem;
        padding-bottom: 0.2rem;

    }
}