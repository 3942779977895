.modal-produit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.251);
    z-index: 3000;
    animation: bgdetails1 0.5s;


    .card-details-produit {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 1100px;
        margin: auto;
        display: flex;
        justify-content: space-between;
        background-color: white;
        padding: 2rem 1rem;
        padding-left: 2rem;
        border-radius: 0.4rem;
        animation: popupdetails 0.8s;
        box-shadow: 0 0.5rem 2.2rem rgba(0, 0, 0, 0.241);


        .cross {
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            padding: 1rem;
            border-radius: 10rem;
            background-image: $pictoCross;
            background-repeat: no-repeat;
            background-position: center;
            background-size: center / 60%;
            background-color: rgb(255, 255, 255);
            cursor: pointer;
            box-shadow: 0 0 1rem rgba(0, 0, 0, 0.068);
        }

        .card-img-produit {
            width: 55%;
            height: auto;
            box-shadow: -0.5rem 0.5rem 1.2rem rgb(206, 206, 206);
            border-radius: 0.4rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }


        }

        .details-produit {
            width: 50%;
            display: flex;
            flex-direction: column;
            margin: 1rem 1rem 2rem 2rem;
            font-family: $primary-font;

            h2,
            h1 {
                text-align: center;
                letter-spacing: 0.2rem;
            }

            .description {
                text-align: justify;
                padding-right: 1rem;
                margin: 2rem 0 1rem;
                min-height: 50px;
            }

            .allergenes {
                font-size: 0.9rem;
                font-weight: bold;
            }

            .select-options {
                display: flex;
                flex-direction: column;
                margin: 1rem 0 1rem;

                .select-condi {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    margin-bottom: 1rem;

                    h4 {
                        width: 100%;
                        margin-bottom: 0.5rem;
                    }

                    
                }

                .select-quantity {
                    display: flex;
                    align-items: center;

                    h4 {
                        padding-top: 0.2rem;
                    }
                    
                    #quantity {
                        width: 3rem;
                        margin-left: 0.7rem;
                        box-shadow: 0.05rem 0.1rem 0.2rem rgb(189, 189, 189);
                        cursor: pointer;
                        padding: 0.5rem 0;
                        
                        p {
                            font-family: $secondary-font;
                            font-weight: bold;
                            width: auto;
                            text-align: center;
                        }
                        
                        .options-quantite {
                            position: fixed;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            left: 0;
                            z-index: 5000;
                            background-color: rgba(0, 0, 0, 0.068);
                            animation: bgdetails2 0.3s;
                            
                            ul#options-list {
                                position: absolute;
                                list-style: none;
                                text-align: center;
                                background-color: white;
                                box-shadow: 0 0.5rem 0.4rem rgb(187, 187, 187);
                                animation: slideinmquant 0.3s;
                                
                                li {
                                    font-family: $secondary-font;
                                    font-weight: bold;
                                    padding: 0.5rem 0;
                                    font-size: 1.2rem;
                                }
                            }

                        }

                    }
                    
                }
                
                
                label {
                    display: flex;
                    font-family: $secondary-font;
                    font-weight: bold;
                    cursor: pointer;
                    margin: 0 0.5rem;
                    
                    .checkbox {
                        width: 1rem;
                        height: 1rem;
                        border-radius: 1rem;
                        margin-right: 0.5rem;
                        box-shadow: 1.58322px 3.16644px 3.16644px rgba(0, 0, 0, 0.17), inset 0px 1.95805px 3.95805px rgba(0, 0, 0, 0.25);
                        
                        &.checked {
                            background: #D3AD92;
                        }
                    }
                }
                
                .condi {
                    width: 20%;
                }
            }
            .ref{
                width: 100%;
                position: relative;
            }
            .hidden {
                position: absolute;
                top: 0;
                height: 0;
                opacity: 0;
                width: 100%;
                text-align: center;
                transition: all 0.5s ease;

            }

            .error {
                position: absolute;
                top: 0;
                width: 100%;
                height: 25px;
                opacity: 1;
                transition: all 0.5s ease;
                text-align: center;
                color: rgb(186, 15, 15);


            }

            .montants {
                margin: 1.5rem 0 2rem;
                display: flex;
                justify-content: flex-end;
                align-items: baseline;
                font-family: $secondary-font;
                
                h1 {
                    font-size: 1.5rem;
                }

                p {
                    margin-left: 5%;
                    width: 30%;
                    font-weight: bold;
                }
            }

            button {
                padding: 1rem 2rem;
                width: fit-content;
                margin: auto;
                font-size: 1rem;
                font-family: $primary-font;
                letter-spacing: 0.2rem;
                border: none;
                background-color: #634236;
                color: white;
                border-radius: 0.4rem;
                box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.336);
                cursor: pointer;
                transition: all 0.4s ease;

                &:hover {
                    color: #D5B298;
                    transition: all 0.3s ease;
                    box-shadow: 0 5px 10px #D5B298;
                }
            }

        }
    }
}


@keyframes popupdetails {
    0% {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
    }

    10% {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0);
    }

    100% {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(1);
    }
}

@keyframes bgdetails1 {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.251);

    }
}

@keyframes bgdetails2 {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.068);

    }
}

@keyframes slideinmquant {
    from {
        height: 0;
        overflow: hidden;
    }

    to {
        height: 152px;

    }
}


// @media screen and (max-width: 1100px) {
//     .modal-produit {


//         .card-details-produit {
//             width: 100%;
//             min-width: 600px;
//             max-height: fit-content;
//             margin: auto;
//             background-color: white;
//             padding: 2rem 1rem;
//             border-radius: 0.4rem;

//             .card-img-produit {
    //                 width: 90%;
    //                 height: 300px;
    //                 margin: auto;

    
    
    //             }
    
    //             .details-produit {
        //                 width: 80%;
        //             }
        //         }
        //     }
        // }
        
        @media screen and (max-width: 1100px) {
            .modal-produit {
                position: fixed;

                .card-details-produit {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: fixed;
                    top: 0;
                    left: 0;
                    transform: translate(0, 0);
                    width: 100vw;
                    height: 100vh;
                    min-width: auto;
                    padding: 2rem;
                    padding-top: 1rem;
                    
            .card-img-produit {
                width: 100%;
                height: 50vh;
                max-width: 700px;
                max-height: 100%;
                min-height: 100px;
                margin: auto;
                margin-top: 0;
                padding: 0;



            }

            .details-produit {
                width: 100%;
                margin: 0;
                position: relative;


                h1 {
                    margin-top: 1rem;
                    font-size: 1.4rem;
                }
                .textToScroll{
                    max-height: 25vh;
                    overflow-y: auto;
                }
                .montants{
                    margin-top: 0;
                }

                
            }
        }
    }
}