.clients-admin {
    width: 100%;
    box-shadow: 0 0 1.4rem rgb(171, 171, 171);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-family: $primary-font;
        letter-spacing: 0.1rem;
        font-weight: normal;
        padding: 1.5rem;
    }

    .create-account {
        position: absolute;
        align-self: flex-end;
        transform: translate(-30%, 70%);
        padding: 0.5rem 1rem;
        font-family: $primary-font;
        font-size: 1rem;
        background-color: #634236;
        color: white;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
    }

    .header-clients {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2rem;

        h3 {
            font-family: $primary-font;
            font-weight: bold;
            text-align: start;
            width: 14%;
            &.large{
                width: 19%;
            }
            &.small{
                width: 2%;
            }
            &.medium{
                width: fit-content;
                margin-right: 2rem;
            }
        }
    }
}