@import '../variables.scss';


// .optionsQuantite {
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     z-index: 5000;
//     background-color: rgba(0, 0, 0, 0.068);

    .optionsList {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(17px, 88%);
        list-style: none;
        width: fit-content;
        min-height: 20px;
        text-align: center;
        background-color: white;
        box-shadow: 0 0.5rem 0.4rem rgb(187, 187, 187);
        z-index: 50;

        li {
            font-family: $secondary-font;
            font-weight: bold;
            padding: 0.5rem 0;
            margin-bottom: 0.4rem;
            font-size: 1.2rem;
            cursor: pointer;
            position: relative;

        }
        
        input {
            width: 90%;
            margin-top: 0.5rem;
            border: none;
            padding: 0.5rem;
            box-shadow: 0 0 0.5rem rgb(190, 190, 190);
            
        }
        
        button {
            width: 90%;
            color: #D5B298;
            background-color: #634236;
            border-radius: 0.3rem;
            border: none;
            font-family: $primary-font;
            font-weight: bold;
            margin: 1rem 0 0.5rem;
            padding: 0.5rem;
            box-shadow: 0 0 0.5rem rgb(190, 190, 190);
            cursor: pointer;
            
            &:hover{
                transition: all 0.7s ease;
                box-shadow: 0 3px 10px #D5B298;
            }
        }
    }
    .iconDelete {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: $pictoDelete;
        width: 20px;
        height: 20px;
        background-size: cover;
        cursor: pointer;

    }
    
    // }