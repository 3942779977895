.gourmandises {
    max-width: 1500px;
    margin: auto;
    // overflow-y: auto;

    h1 {
        font-family: $primary-font;
        font-weight: lighter;
        font-size: 3rem;
        text-align: center;
        letter-spacing: 0.5vw;
    }

    .banniere {
        background: center/100% url("../../assets/images/produits.jpg") no-repeat;
        width: 90%;
        height: 40vh;
        margin: 1em auto;
    }

    .barreTri {
        width: 85%;
        min-height: 8vh;
        height: fit-content;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        background: white;
        border-radius: 0.5em;
        overflow: visible;
        
        label {
            font-family: $secondary-font;
            font-weight: bolder;
            
            &.referedLabel {
                font-size: 1.5rem;
                min-width: 60px;
                position: relative;
                display: flex;
                align-items: center;
                padding-right: 20px;

            }

        }
    }

    .list-produits {
        width: 100%;
        height: fit-content;
        margin: auto;
        margin-bottom: 3rem;
        padding: 1rem 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

}

@media screen and (max-width: 1000px) {
    .gourmandises {
        max-width: 1500px;
        margin: auto;
        overflow-y: auto;

        h1 {
            font-size: 2rem;
        }

        .banniere {
            display: none;
        }

        .barreTri {
            width: 100%;
            height: 8vh;
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;
            border-radius: 0.5em;

            label {
                font-family: $secondary-font;
                font-weight: bolder;
                font-size: 1.1rem;
               
                input {
                    margin: 0;
                    height: 2.2rem;
                    border: none;
                    box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.25),
                        inset 0px 0px 13px rgba(0, 0, 0, 0.18);
                }
            }
        }
        .list-produits {
            width: 100%;
            height: fit-content;
            margin-bottom: 3rem;
            padding: 1rem 0;
        }
    }
}

@media screen and (max-width: 550px) {
    .gourmandises {
       
        .barreTri {
            height: fit-content;
            flex-direction: column;
            label {
                margin: 1rem;
                display: flex;
                flex-direction: column;
                align-items: center;

                
            }
        }
    }
}