
::placeholder{
  color: rgb(34, 34, 34);
}
.page-contact {
  width: 65%;
  max-width: 1500px;
  height: 75vh;
  margin: 1em auto 2em;
  display: flex;
  background: white;
  transform: translateX(2em);
  box-shadow: 0px 0px 45px 6px rgba(0, 0, 0, 0.25);

  section {
    &:nth-child(1) {
      background: #634236;
      width: 40vw;
      max-width: 400px;
      position: relative;
      height: auto;
      min-height: fit-content;
      margin: 2em 0;
      display: flex;
      flex-direction: column;
      padding: 2em 3em;
      font-family: $primary-font;
      color: white;
      transform: translateX(-3em);
      box-shadow: 0px 0px 31px rgba(0, 0, 0, 0.53);
      article{
        display: flex;
        align-items: center;
        margin: 1rem 0;
      }

      h2 {
          width: 100%;
        text-align: center;
        grid-area: titre;
        font-weight: bold;
      }
      p{
          height: fit-content;
          font-family: $primary-font;
      }
      .icon-mail{
        background: $pictoMail;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        margin-right: 2rem;
      }
      .icon-phone{
        background: $pictoPhone;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        margin-right: 2rem;
      }
      .icon-location{
        background: $pictoLocation;        
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 30px;
        height: 30px;
        margin-right: 2rem;
      }

    }

    &:nth-child(2) {
      width: 100%;
      margin: 1.5rem 2.5rem 2em 3em;     
      h2 {
        font-family: $primary-font;
        margin: 2em 1em;
        letter-spacing: 0.1em;
      }
      
      form {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          max-width: 600px;
        input {
          margin: 0.5em 0;
          width: 100%;
          height: 7vh;
          background: #F2F2F2;
          font-family: $secondary-font;
          font-size: 1rem;
          border: 1px solid #f2f2f2;
          padding-left: 1.5em;
          z-index: 10;

        }

        textarea {
          background: #F2F2F2;
          font-family: $secondary-font;
          margin: 0.4em 0;
          height: 20vh;
          font-size: 1rem;
          border: none;
          padding: 1em 0 0 1.5em;
          resize: none;
        }
        input[type=submit]{
            align-self: center;
            width: 8em;
            height: 6vh;
            padding:0;
            border-radius: 1em;
            border: none;
            color: white;
            font-weight: bold;
            background: #634236;
            box-shadow: 0px 4px 10px #634236;
            cursor: pointer;
        }
      }
    }
  }
}
@media screen and (min-width: 700px) and (max-width: 1200px) {
  .page-contact {
    width: 90%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 3rem;
    flex-direction: column;
    transform: translateX(0);
  
    section {
      &:nth-child(1) {
        width: 90%;
        max-width: none;
        margin: 2em auto 0 auto;
        flex-direction: row;
        flex-wrap: wrap;
        transform: translateX(0);
       justify-content: space-evenly;
  
        h2 {
          width: 100%;
          margin-bottom: 1rem;
        }
 
      }
  
      &:nth-child(2) {
        width: 100%;
        margin: auto;   
        h2 {
          width: fit-content;
          margin: 2em auto;
        }
        
        form {
           max-width: 500px;
            margin: auto;
            margin-bottom: 1rem;
        
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .page-contact {
    width: 100%;
    height: auto;
    margin: 0 auto;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    transform: translateX(0);
  
    section {
      &:nth-child(1) {
        width: 100%;
        max-width: none;
        margin: 2em auto 0 auto;
        display: flex;
        flex-wrap: wrap;
        transform: translateX(0);
       justify-content: space-evenly;
  
        h2 {
          width: 100%;
          margin-bottom: 1rem;
        }
        p{
          width: 90%;
          margin-bottom: 0.5rem;
          align-self: center;
        }
        p{
          width: 90%;
        }
 
      }
  
      &:nth-child(2) {
        width: 100%;
        margin: auto;   
        h2 {
          width: fit-content;
          margin: 2em auto;
        }
        
        form {
           max-width: 300px;
            margin: auto;
            margin-bottom: 1rem;
        
        }
      }
    }
  }
}
