.fond-modal{
    .modal-mrc{
        position: relative;
        width: 40%;
        min-width: 660px;
        margin: 5vh auto;
        height: fit-content;
        max-height: 90vh;
        overflow-y: auto;
        background: white;
        font-family: $primary-font;
        padding: 2rem 4rem;
        border-radius: 0.5rem;
        h1{
            text-align: center;
            margin-bottom: 2rem;
        }
        h2{
            text-indent: 2rem;
            margin-top: 0.7rem;
            margin-bottom: 0.5rem;
        }
        p{
            text-align: justify;
        }
        .indent{
            text-indent: 3rem;
        }
        .bigList{
            li{

                margin: 0.8rem 0;
            }
        }
        .subList{
            padding-left: 4rem;
        }
        blockquote{
            padding-left: 4rem;
        }
        .cross{
            position: absolute;
            top: 20px;
            right: 20px;
            width: 20px;
            height: 20px;
            background-image: $pictoCross;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            cursor: pointer;
        }
        
    }
}

@media screen and (max-width: 680px) {
    .fond-modal{
        .modal-mrc{
            width: 100%;
            height: 100%;
            min-width: auto;
            max-height: none;
        padding: 4rem 1rem;
        margin: 0;
        }
    }
}