.nav-admin{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    font-family: $primary-font;

    .logo-accueil {
        width: 300px;
        height: 220px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
   h3{
       font-weight: normal;
   }
   a{
       padding: 0.5rem 3.5rem;
       background-color: #634236;
       color: white;
       text-decoration: none;
       border-radius: 1rem;
   }
}