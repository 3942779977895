$primary-font: 'OFLSortsMillGoudy';
$secondary-font: 'PoiretOne';
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.fond-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.211);
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.086) rgba(0, 0, 0, 0.07);
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.07);
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.086);
    border-radius: 20px;
    border: 3px solid rgba(0, 0, 0, 0.07);
  }


@font-face {
    font-family: 'OFLSortsMillGoudy';
    src: local("OFLSortsMillGoudy"), url(../fonts/ofl-sorts-mill-goudy/OFLGoudyStMTT.ttf) format('truetype');
}
@font-face {
    font-family: 'PoiretOne';
    src: local("PoiretOne"), url(../fonts/poiret-one/PoiretOne-Regular.ttf) format('truetype');
}

/** Pictos SVG **/

$pictoAddImage:url("../icons/add-media-default.svg");
$pictoArrowDownFilled:url("../icons/arrow-down-filled.svg");
$pictoArrowRight:url("../icons/arrow-right.svg");
$pictoCreditCard:url("../icons/credit-card.svg");
$pictoCross:url("../icons/cross.svg");
$pictoDelete:url('../icons/trash-interface.svg');
$pictoEdit:url('../icons/edit-pen.svg');
$pictoLocation:url('../icons/location.svg');
$pictoMail:url('../icons/mail.svg');
$pictoMenuBurger:url('../icons/menu-burger.svg');
$pictoPanier:url("../icons/shopping-bag.svg");
$pictoPhone:url('../icons/phone.svg');
$pictoShop:url("../icons/shop.svg");
$pictoUser:url('../icons/user.svg');


/** Images **/
$logoCompletMarron:url("../images/logo/ML-logo_quadri_fdmarron.jpg");
$logoCompletTransp:url("../images/logo/ML-logo_quadri_ssfd.png");
$logoCompactMarron:url("../images/logo/ML-logo-compact_quadri_fdmarron.jpg");
$logoCompactTransp:url("../images/logo/ML-logo-compact_quadri_ssfd.png");
$logoInitialesMarron:url("../images/logo/ML-logo-Initiales_quadri_fdmarron.jpg");
$logoInitialesTransp:url("../images/logo/ML-logo-Initiales_quadri_ssfd.png");