.produits-admin {
    width: 100%;
    box-shadow: 0 0 1.4rem rgb(171, 171, 171);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-family: $primary-font;
        letter-spacing: 0.1rem;
        font-weight: normal;
        padding: 1.5rem;
    }

    .create-produit {
        position: absolute;
        align-self: flex-end;
        transform: translate(-30%, 70%);
        padding: 0.5rem 1rem;
        font-family: $primary-font;
        font-size: 1rem;
        background-color: #634236;
        color: white;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
    }

    .header-produits {
        width: 95%;
        
        display: flex;
        align-items: center;
        flex-direction: row;
        margin: 0 auto 1rem;
        padding-left: 3%;
    gap: 0.5rem;


        h3 {
            font-family: $primary-font;
            font-weight: bold;
            width: 14%;
        }
        .date {
            width: 11%;
            text-align: center;
        }
    
        .titre{
            text-align: center;
            width: 15%;
        }
    
        .categorie{
            width: 15%;
            text-align: center;
        }
    
        .description{
            width: 23%;
        }
        .prix{
            width: 7%;
        }
        .condi {
            width: fit-content;
        }
        .statut {
            text-align: center;
            width: 7%;
        }
    }
    .list-produits{
        width: 100%;
        overflow-y: auto;
    }
}