.fond-modal{
    

    .modal-client{
        width: 20%;
        min-width: 360px;
        height: auto;
        max-height: 100vh;
        overflow-y: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;

        h2{
            font-family: $primary-font;
            letter-spacing: 0.2rem;
            text-align: center;
            margin-top: 1rem;
        }

        form{
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 1rem 3rem;

            label{
                font-family: $primary-font;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                margin: 0.5rem 0;

                input{
                    font-size: 0.9rem;
                    margin-top: 0.2rem;
                    padding: 0.7rem;
                    text-align: center;
                    border: none;
                    box-shadow: 0 0 1rem rgb(189, 189, 189);
                }
            }

            .buttons{
                margin-top: 1rem;
                display: flex;
                justify-content: space-evenly;
                button{
                    font-family: $primary-font;
                    padding: 0.4rem 1.5rem;
                    border: none;
                    cursor: pointer;
                    border-radius: 1rem;
                    box-shadow: 0 0.2rem 0.7rem rgb(159, 159, 159);
                    
                    &.valider{
                        color: rgb(226, 226, 226);
                        background-color: #634236;
                    }

                    &.annuler{
                        background-color: white;
                    }
                }
            }
        }
    }
}