.commandes-admin {
    width: 100%;
    box-shadow: 0 0 1.4rem rgb(171, 171, 171);
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        font-family: $primary-font;
        letter-spacing: 0.1rem;
        font-weight: normal;
        padding: 1.5rem;
    }


    .header-commandes {
        align-self: flex-start;
        margin-left: 0.5rem;
        width: 82%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 2rem;

        h3 {
            font-family: $primary-font;
            font-weight: bold;
            text-align: center;
           
            &.date{
                width: 10%;
                margin-right: 1rem;
            }
            &.clientName{
                width: 19%;
            }
            &.details{
                width: 35%;
            }
            &.montant{
                width: 15%;
            }
            &.statut{
                width: 10%;
            }
        }
    }
    .list-commandes {
        width: 100%;
        overflow-y: scroll;
    }
}