.fondModal {
    z-index: 2000;
    background-color: transparent;
    animation: blinkOpacity 3s;



    .succes-ajout {
        position: fixed;
        top: 30px;
        right: 10px;
        max-width: 340px;
        height: 200px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 0.7rem;
        z-index: 4000;
        border-radius: 0.4rem;
        box-shadow: 0 0 1rem rgb(165, 165, 165);
        animation: 
            moveIn 1s,
            moveOut 1s 5s;

        h3 {
            font-family: $primary-font;
        }

        p {
            font-family: $primary-font;
        }

        .produit-ajoute {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 0.7rem;

            .image {
                width: 100px;
                height: 70px;

                img {
                    border-radius: 0.5rem;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

        }

        .btn-redirect {
            display: flex;
            flex-direction: column;
            width: 100%;

            a {
                text-decoration: none;
                width: 90%;
                margin: 1rem auto;
                text-align: center;
                border-radius: 0.2em;
                padding: 0.7rem;
                border: none;
                cursor: pointer;
                font-family: $primary-font;
                letter-spacing: 0.1rem;
                font-size: 0.9rem;

                &:nth-child(1) {
                    color: white;
                    background: #634236;
                    box-shadow: 0 5px 10px #634236;
                    transition: all 0.3s ease;

                    &:hover {
                        color: #D5B298;
                        transition: all 0.3s ease;
                        box-shadow: 0 5px 10px #D5B298;
                    }
                }
            }
        }
    }
}

@keyframes moveIn {
    from {
        top: 30px;
        right: -350px;
    }

    to {
        top: 30px;
        right: 10px;
    }
}

@keyframes moveOut {
    from {
        top: 30px;
        right: 10px;
    }

    to {
        top: 30px;
        right: -350px;
    }
}

@keyframes blinkOpacity {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    50% {
        background-color: rgba(0, 0, 0, 0.211);
    }

    to {
        background-color: rgba(0, 0, 0, 0);
    }
}