.apropos{
    
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80%;
    min-width: 1000px;
    height: fit-content;
    min-height: 760px;
    margin: 2em auto;
    padding: 2em;
    gap: 2rem;
    font-family: $primary-font;
    box-shadow: 0 0 4em rgba(0, 0, 0, 0.171);
    .image{
        width: 30%;
        height: 100%;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .listArticles{
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: fit-content;

        .articles{
            height: fit-content;
            h2{
                margin: 2rem 0;
            }
            p{
            width: 100%;
            text-align: justify;
        }
        .horaires{
            width: fit-content;
            .jour{
                gap: 0.5rem;
                width: 100%;
                display: flex;
                p{
                    font-size: 1.05rem;
                    min-width: 94px;
                }
            }
        }
    }

    }
}

@media screen and (max-width: 1000px) {
    .apropos{
        width: 100%;
        min-width: 280px;
        flex-direction: column;
        padding: 1rem;
        height: fit-content;
        .image{
            width: 100%;
            max-width: 500px;
        }
        .listArticles{
            position: static;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            .articles{
                h2{
                text-align: center;
                }
                .horaires{
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .apropos{
        width: 100%;
        min-width: 280px;
        flex-direction: column;
        padding: 1rem;
        height: fit-content;
        .image{
            width: 100%;
        }
        .listArticles{
            position: static;
            align-items: center;
            flex-wrap: nowrap;
            width: 100%;
            .articles{
                .horaires{
                    margin-bottom: 1rem;
                }
            }
        }
    }
}