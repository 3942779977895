@import './variables.scss';

.optionsList {
    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
    justify-content: space-evenly;
    list-style: none;
    align-items: center;
    font-size: 1rem;
}

.chip, .selected {
    border-radius: 1rem;
    font-family: $primary-font;
    font-weight: bold;
    margin: 1rem 0 0.5rem;
    padding: 0.3rem 1.5rem;
    box-shadow: 0 0 0.5rem rgb(190, 190, 190);
    cursor: pointer;
    transition: all 0.7s ease;

    &:hover{
        transition: all 0.4s ease;
    box-shadow: 0 0 1rem rgb(200, 200, 200);

    }

}

.selected{
    color: #D5B298;
            background-color: #634236;
            border: none;
}