.modal-creation-produit {
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 30%;
    min-width: 600px;
    :focus{
        outline: none;
    }

    form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 1.5rem;

        .add-preview-images {
            width: 50%;

            .addImage {
                width: 100%;
                height: 100%;
                background-image: $pictoAddImage;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                cursor: pointer;
            }
            .preview-images {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                img{
                    width: 90%;
                    margin:auto;
                    margin-top: 1rem;

                    &.mini{
                        width: fit-content;
                        max-width: 30%;
                        margin-top: 0.5rem;
                        height: 3rem;
                        max-height: fit-content;
                    }
                }
            }

        }

        .titre-description {
            width: 50%;
            padding: 1rem;

            p {
                font-family: $primary-font;
                font-weight: bold;
                text-align: center;
                margin-bottom: 0.7rem;

            }

            input {
                width: 100%;
                margin-bottom: 1rem;
                padding: 0.5rem;
                border: none;
                box-shadow: 0 0 0.8rem rgb(196, 196, 196);

            }

        }
        textarea {
            width: 100%;
            height: 4rem;
            border: none;
            resize: none;
            box-shadow: 0 0 0.8rem rgb(196, 196, 196);
        }

        label {
            font-family: $primary-font;
            font-weight: bold;
            width: 50%;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: 0.2rem 1rem;

            &.referedLabel {
                position: relative;
            }

            input {
                width: 100%;
                font-family: $primary-font;
                font-size: 1rem;
                text-align: center;
                margin-bottom: 1rem;
                margin-top: 0.4rem;
                padding: 0.5rem;
                border: none;
                box-shadow: 0 0 0.8rem rgb(196, 196, 196);

                &[type=button] {
                    background-color: white;
                    transition: all 0.3s ease;

                    &:hover {
                        cursor: pointer;
                        transition: all 0.3s ease;
                        box-shadow: 0 0 1.5rem rgb(196, 196, 196);

                    }
                }
            }

            .add-property {
                display: flex;
                flex-wrap: nowrap;

                input {
                    width: 100%;
                    margin-bottom: 1rem;
                    margin-right: 0.5rem;
                    padding: 0.5rem;
                    border: none;
                    box-shadow: 0 0 0.8rem rgb(196, 196, 196);

                    &[type=button] {
                        width: 2rem;
                        margin-right: 0;
                        background-color: white;
                        transition: all 0.3s ease;

                        &:hover {
                            cursor: pointer;
                            transition: all 0.3s ease;
                            box-shadow: 0 0 1.5rem rgb(196, 196, 196);

                        }
                    }
                }
            }

        }

        .aller-quanti {
            width: 50%;
            padding: 0.2rem 1.5rem;

            h2 {
                font-family: $primary-font;
                font-size: 1rem;
            }

            ul {
                width: 90%;
                min-height: 50px;
                max-height: 100px;
                overflow-y: auto;
                margin: auto;
                list-style: none;

                li {
                    font-family: $primary-font;

                }
            }

        }

        .btns {
            width: fit-content;
            margin: auto;
            display: flex;
            justify-content: space-around;

            button {
                font-family: $primary-font;
                padding: 0.4rem 1.5rem;
                margin: 0 1.5rem;
                border: none;
                cursor: pointer;
                border-radius: 0.8rem;
                box-shadow: 0 0.2rem 0.7rem rgb(159, 159, 159);

                &.valider {
                    color: rgb(226, 226, 226);
                    background-color: #634236;
                }

                &.annuler {
                    background-color: white;
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {

    .modal-creation-produit {
       
        width: 100%;
        min-width: 100%;
        height: 100vh;
    
        form {
            flex-direction: column;
            align-items: center;
            flex-wrap: nowrap;
            overflow-y: auto;
            padding-bottom: 5rem;
    
            .add-preview-images {
                width: 100%;
    
                .addImage {
                    height: 150px;
                }
    
            }
    
            .titre-description {
                width: 100%;
    
                input {
                    padding: 1rem;    
                }
    
            }
            textarea {
                height: 7rem;
            }
    
            label {
                width: 100%;
                margin-bottom: 1rem;
    
    
                input {
                    padding: 1rem;
    
                }
            }
    
            .aller-quanti {
                width: 100%;
    
            }
    
            .btns {
                width: 100%;
                margin: auto;
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                justify-content: space-evenly;
    
                button {
                    font-family: $primary-font;
                    padding: 0.7rem 2rem;
                    border: none;
                    cursor: pointer;
                    margin: 0 0.2rem;
                }
            }
        }
    }

}