.page-apropos {
    max-width: 1500px;
    margin: auto;

    section {
        display: flex;
        background: white;
        margin: 1rem auto;
        padding: 2rem;
        font-family: $primary-font;
        width: 80%;
        box-shadow: 2px 4px 16px 2px rgba(0, 0, 0, 0.25);
        align-items: center;
        img{
            max-width: 400px;
            height: 80%;
            object-fit: cover;
        }

        article {
            h2 {
                font-weight: normal;
                font-size: 1.5rem;
                margin-left: 4rem;
                letter-spacing: .1em;
            }

            p {
                margin: 3rem 2rem;
                letter-spacing: 0.04em;
                text-align: justify;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .page-apropos {
        section {
            width: 100%;
            flex-direction: column;
            height: auto;
            padding: 2rem 0.5rem;

            &.reverse {
                flex-direction: column-reverse;
            }

            img {
                width: 60%;
                min-width: 300px;
                margin-bottom: 1.5rem;
            }

            article {
                p {
                    margin: 1.5rem;
                }
            }
        }
    }
}


@media screen and (max-width: 600px) {
    .page-apropos {
        section {
            
            article {
                h2 {
                    margin: auto;
                    text-align: center;
                }
            }
        }
    }
}