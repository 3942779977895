.main-edit {
    padding: 80px 3vw;
    padding-left: calc(3vw + 275px);
    transition: all 0.5s ease;
    background-color: #ffffff;
    min-height: 100vh;
    transition: all 0.5s ease;
}



.editingAsideMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 275px;
    padding: 16px;
    padding-top: 0;
    background-color: #fcfcfc;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    z-index: 3;
    transition: all 0.5s ease;
    &.closed{
        left: -300px;
        transition: all 0.5s ease;
    }
    
    .logo-full {
        width: 100%;
        height: 180px;
        object-fit: contain;
    }

    .floatMenu{
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    
    a {
        color: rgb(46, 43, 67);
        font-weight: 700;
    }
    
    ul {
        list-style: none;
        width: 100%;
        padding: 1rem;
        overflow-y: auto;

        li {
            margin: 5px 0 8px;
            padding: 5px 16px;
            font-weight: 600;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;


            &:hover,
            &.active {
                color: rgb(0, 0, 0);
                background: #d5b29833;
                ;
            }

            p {
                line-height: 30px;
                margin-top: 4px;
                margin-bottom: 4px;
                vertical-align: middle;
                max-width: 155px;
            }

        }
    }
}

.stepperContainer {
    width: calc(100% - 275px - 6vw);
    position: fixed;
    bottom: 0;

    &.closed{
        width: 100%;
    }

}

.stack {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    &.centered {
        align-items: center;
    }
}

.introduction {
    position: relative;
    padding: 1rem 1.5rem 100px;
    height: fit-content;
    overflow-y: auto;

    h2 {
        text-align: center;
        margin-bottom: 30px;
        font-family: $primary-font;
    }

    p {
        text-align: justify;
        margin-top: 20px;
        font-family: $primary-font;
        font-size: 1.2rem;
    }
    input{
        width: 100%;
        padding: 10px;
        border: none;
        border-bottom: 1px solid #d5b298;
        font-family: $primary-font;
        font-size: 1.2rem;
        margin-bottom: 20px;
        outline: none;
        transition: all 0.2s ease-in-out;
        &::placeholder{
            color: #868686;
            font-style: italic;
        }
        &:focus{
            border-bottom: 1px solid #634236;
        }
        
    }
    textarea {
        width: 100%;
        padding: 10px;
        border: none;
        border-bottom: 1px solid #d5b298;
        font-family: $primary-font;
        font-size: 1.2rem;
        margin-bottom: 20px;
        outline: none;
        transition: all 0.2s ease-in-out;
        resize: none;
        &::placeholder{
            color: #868686;
            font-style: italic;
        }
        &:focus{
            
        border: 1px solid #d5b298;
        }
    }
    .error{
        border-bottom: none;
        border: 1px solid #c20202;
        &::placeholder{
            color: #b00000;
            font-style: italic;
        }
    }

    button {
        margin: auto;
        margin-top: 20px;
        padding: 8px 16px;
        width: fit-content;
        color: #f1caad;
        background-color: #634236;
        font-family: $primary-font;
        font-weight: 600;
        border: none;
        border-radius: 0.5rem;
        cursor: pointer;
        letter-spacing: 1px;

        &[disabled] {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }
    .btn{
        margin-bottom: 1.5rem;
    }

}