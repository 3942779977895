@import "../variables.scss";

.carrouselAdmin {
    width: 100%;
    height: 70vh;
    box-shadow: 0 0 1.4rem rgb(171, 171, 171);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 2rem;

    h2 {
        width: 100%;
        font-family: $primary-font;
        letter-spacing: 0.1rem;
        font-weight: normal;
        padding: 1.5rem;
        text-align: center;
    }

}

.formImage {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10rem;
    text-align: center;
}
.valider {
    font-family: $primary-font;
                padding: 0.4rem 1.5rem;
                margin: 0 1.5rem;
                border: none;
                cursor: pointer;
                border-radius: 0.8rem;
                box-shadow: 0 0.2rem 0.7rem rgb(159, 159, 159);
                color: rgb(226, 226, 226);
                background-color: #634236;

}
.addImage {
    width: 100px;
    height: 100px;
    background-image: url('../../icons/add-media-default.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: pointer;
}