.page-profil {
    width: 90%;
    margin: 0 auto 4rem;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 1rem rgb(212, 212, 212);

    h3 {
        width: fit-content;
        margin: auto;
        padding: 1rem 0;
        font-family: $primary-font;
        ;
        letter-spacing: 0.1rem;
    }

    // section{

    // }
    h4 {
        width: 90%;
        font-family: $primary-font;
        font-size: 1.4rem;
        font-weight: normal;
        letter-spacing: 0.1rem;
    }

    .profil_infos {
        position: relative;
        width: 95%;
        height: fit-content;
        margin: 0 auto 1.2rem;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0 0.2rem 0.5rem rgb(212, 212, 212);
        padding: 1rem;

        .infos-persos {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 1rem 2rem;
            font-family: $primary-font;
            max-width: 300px;


            label {
                width: 100%;
                display: flex;
                flex-direction: column;
                text-indent: 1.5rem;
                max-width: 400px;
                font-weight: bold;
                max-width: 300px;

                input {
                    width: 100%;
                    margin: 0.5rem 0;
                    text-align: center;
                    font-family: $primary-font;
                    font-size: 1rem;
                    padding: 0.5rem;
                    border: none;
                    box-shadow: 0 0 0.8rem rgb(196, 196, 196);

                }
            }
            .btns{
                margin-top: 1rem;
                width: 100%;
                max-width: 400px;
                display: flex;
                justify-content: space-evenly;

                button {
                    font-family: $primary-font;
                    font-weight: bold;
                    letter-spacing: 0.1rem;
                    padding: 0.4rem 1.5rem;
                    margin: 0 1.5rem;
                    border: none;
                    cursor: pointer;
                    border-radius: 0.8rem;
                    box-shadow: 0 0.2rem 0.7rem rgb(159, 159, 159);
    
                    &.valider {
                        color: rgb(226, 226, 226);
                        background-color: #634236;
                    }
    
                    &.annuler {
                        background-color: white;
                    }
                }
            }

            p {
                max-width: 300px;
            }
        }
    }

    .profil_commande {
        width: 95%;
        height: fit-content;
        margin: 0 auto 1.2rem;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0 0.2rem 0.5rem rgb(212, 212, 212);
        padding: 1rem;

        h4{
            margin-bottom: 0.5rem;
        }
        .liste-commandes{
            width: 100%;
            max-height: 50vh;
            overflow-y: auto;
            display: flex;
            justify-content: space-around;
            gap: 1.5rem;
            flex-wrap: wrap;
            padding-bottom: 1rem;

            .item-carte-commande{
                width: 100%;
                max-width: 400px;
                height: 120px;
                display: flex;
                padding: 0.6rem;
                border-radius: 0.3rem;
                gap: 0.7rem;
                padding-bottom: 10px;
                box-shadow: 0 0.2rem 0.5rem rgb(208, 208, 208);

                .image{
                    min-width: 100px;
                    width: 100px;
                    height: 100px;
                    margin-left: auto;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .details{
                    width: 100%;
                    height: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    
                    p {
                        font-family: $primary-font;
                        font-size: 1rem;
                    }
                    .date{
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-weight: bold;
                        
                    }
                    .montant{
                        font-family: $secondary-font;
                        -webkit-text-stroke: 1px black;
                        letter-spacing: 0.1rem;
                    }
                    .statut{
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
}

.icon-edit {
    position: absolute;
    right: 10px;
    background: $pictoEdit;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &:hover {
        transition: 0.3s;
        transform: scale(1.1);
    }
}

@media screen and (max-width: 600px) {
    .page-profil {
        width: 100%;
        padding: 1rem 0;


        .profil_infos,
        .profil_commande {
            width: 100%;
        }
        .profil_infos{
            .infos-persos{
                margin: auto;

                p{
                    max-width: 100%;
                    text-align: center;
                }
            }
        }
    }
}