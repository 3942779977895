@import "../variables.scss";

.galerie {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    gap: 3rem;

}
.fullSlide{
    width: 100%;
    height: 40%;
    justify-content: center;

    p{
        padding-left: 2rem;
        text-align: center;
    }
}
.wrapper {
    max-width: 100%;
    min-width: fit-content;
    height: 200px;
    display: flex;
    gap: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
}
.slide{
    min-width: 40%;
    height: 140px;
    position: relative;
        
    p{
        
        text-align: center;
    }
}

.fullSlide, .slide {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    gap: 0.4rem;
    img{
        width: 100%;
        height: 90%;
        object-fit: cover;
    }

}
.iconDelete {
    background: $pictoDelete;
    background-size: cover;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:hover{
        transition: 0.3s;
        transform: scale(1.1);
    }
}