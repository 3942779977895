nav.desktop {
    height: 30vh;
    display: flex;
    align-items: center;
  
    
    ul.desktop {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 60vw;
      max-width: 1200px;
      min-width: 800px;
      margin: auto;
      height: 6.3vh;
      li {
        list-style: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        &:nth-child(1) {
          border-radius: 1rem 0 0 1rem;
          box-shadow: -1rem 0 1rem rgba(85, 85, 85, 0.25);
        }
        &:nth-child(3) {
          border-radius: 0 1rem 1rem 0;
          box-shadow: 1rem 0 1rem rgba(85, 85, 85, 0.25);
        }
  
        a {
          text-decoration: none;
          color: black;
          font-family: $primary-font;
        }
        .logo-accueil {
          width: 20vw;
          min-width: 250px;
          max-width: 350px;
          
        }
      }
    }
    div.panier {
      position: absolute;
      right: 2rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      cursor: pointer;
      transition: 0.3s all ease;
      
      div.number_products{
        background-color: #9B3EAA;
        padding: 0.2rem;
        width: 1.5rem;
        text-align: center;
        color: white;
        z-index: 500;
        border-radius: 10rem;
        transform: translate(40px, -50%);
      }
      &:hover{
        transition: 0.3s all ease;
        transform: scale(1.1);
      }
      
      .icon-panier {
        background-image: $pictoPanier;
        width: 32px;
        height: 32px;
      }
     
        span {
          margin-left: 0.5rem;
          font-family: $primary-font;
        }
      
    }
    div.profil {
      position: absolute;
      top: 0;
      right: 6vw;
      display: flex;
      align-items: center;
      padding: 0.3em 0.5em;
      border-radius: 0.4em;
      box-shadow: 0 0 1.2rem rgb(230, 230, 230);
      cursor: pointer;
      .icon-user{
        background-image: $pictoUser;
        width: 25px;
        height: 25px;
      }
      a {
        text-decoration: none;
        color: rgb(64, 64, 64);
        margin-left: 0.5vw;
        span {
          font-family: $primary-font;
        }
       
      }
      
    }
  }
  .menu-deroulant{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.163);
    
    z-index: 2000;
    animation: bgmderoul 0.5s;
    
    ul{
      position: absolute;
      top: 35px;
      right: 7vw;
      padding: 1rem 2rem;
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgb(243, 243, 243);
      border-radius: 0.4rem;
      box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.188);
      animation: slideinmderoul 0.4s;

    }
    li{
      margin: 0.5rem 0;
      font-family: $primary-font;
    }
  }
  nav.mobile{
    display: none;
  }

  @keyframes slideinmderoul {
    from {
        height: 0;
        overflow: hidden;
    }
    to {
      height: 149px;

    }
}

  @keyframes bgmderoul {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.163);

    }
}
  @media screen and (max-width: 1250px) {
    nav.desktop{
      ul.desktop{
        width: 65vw;
      }

      .panier{
        span{
          display: none;
        }
      }
    }

  }

  @media  screen and (min-width: 1001px) {
    .responsive {
      display: none;
    }
  }
  .menu-mobile, .menu-button-mobile{
    display: none;
  }

  @media  screen and (max-width: 1000px) {
    nav.desktop{
      ul.desktop{
        display: none;
      }
      a{
        margin: 0 auto;
      }
      .responsive{
        max-width: 250px;
        width: 100%;
        height: 100%;
        margin: auto;
        object-fit:cover;
      }
      div.profil{
        top: 5vw;
        right: 0;
      }
      .menu-deroulant{
        ul{
          right: 0;
          top: 5vw;
          transform: translateY(35px);
        }
      }
      .menu-button-mobile{
        display: block;
        position: absolute;
        top:5vw;
        left:6vw;
        width: 40px;
        height: 40px;
        color: rgb(43, 43, 43);
        
      }
    }
    .menu-mobile{
      display: block;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.98);
      z-index: 1000;
      animation: slideltrmpanier 0.5s;


      .cross{
        position: absolute;
        top: 20px;
        right: 20px;
        width: 20px;
        height: 20px;
        padding: 1rem;
        border-radius: 10rem;
        background-image: $pictoCross;
        background-repeat: no-repeat;
        background-position: center;
        background-size: center / 60%;
        background-color: rgb(255, 255, 255);
        cursor: pointer;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.068);
        animation: cross-appear 2s;
    }

      ul{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        list-style: none;
        height: 100%;
        li {

          a{
            text-decoration: none;
            color: black;
            font-family: $primary-font;
            font-size: 1.2rem;
            font-weight: bold;
            letter-spacing: 0.2rem;
          }
        }
      }


    }
  }

  @keyframes slideltrmpanier {
    from {
        transform: translate(-100%, 0)
    }
    to {
        transform: translate(0, 0);

    }
}

@keyframes cross-appear {
  from {
      opacity: 0;;
  }
  to {
      opacity: 1;

  }
}
 

 