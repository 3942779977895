section{
    overflow: hidden;
    margin-bottom: 3rem;
    width: 100%;
    height: 65vh;
    overflow-x: hidden;
    .containerSlide {
      width: fit-content;
      height: 100%;
      display: flex;
      transition: all 1.5s ease;
     
        img{
          width: 100vw;
       height: 100%;
       object-fit: cover;
      }
    }
    
}
@media screen and (max-width: 1000px) {
    article{
        img{
            width:100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
@keyframes slidein {
    from {
      right: 100%;
    }
  
    to {
      right: 0;
    }
  }
