.modal-alert {
    position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15%;
        min-width: 340px;
        min-height: 200px;
        background-color: rgb(247, 247, 247);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 2rem 1rem;
        border-radius: 1rem;
        h3{
            font-family: $primary-font;
            letter-spacing: 0.1rem;
        }
        p {
            margin: auto;
        }
        div.btns{
            width: 100%;
            display: flex;
            justify-content: center;
            button{
                width: 8em;
                height: 2.5em;
                border-radius: 2em;
                border: none;
                margin:auto 1em;
                cursor: pointer;
                font-family: $primary-font;
                &:nth-child(1){
                    font-weight: bold;
                    color: white;
                    background: #8c2d0a;
                    box-shadow: 0 3px 10px #75615a;
                }
                &:nth-child(2){
                    font-weight: bold;
                    color: #35221c;
                    background: #f5f5f5;
                    box-shadow: 0 3px 10px #b6b6b6;
                }
            }
        }
}