.fondPanier {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.404);
    animation: bgmpanier1 0.2s;

    .modalPanier {
        float: right;
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 25vw;
        min-width: 500px;
        background: white;
        padding: 3em;
        transition: all 0.9s;
        animation: slideinmpanier 0.9s;
        .arrow-right {
            position: absolute;
            width: 20px;
            height: 20px;
            background-image: $pictoArrowRight;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &:hover {
                transition: 0.2s;
                cursor: pointer;
                box-shadow: 0 2px 6px rgb(193, 193, 193);
                border-radius: 7px;
            }
        }

        h2 {
            text-align: center;
            font-family: $primary-font;
            font-weight: normal;
        }

        article{
            max-height: 80vh;
            overflow-y: auto;
            overflow-x: hidden;
        }
        .item_panier {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            gap: 1rem;
            margin-bottom: 0.5rem;
            border-bottom: 1px solid rgb(198, 198, 198);
            

            .image {
                width: 8rem;
                min-width: 128px;
                height: 8rem;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .infos-produit {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                margin-top: 1rem;

                p {
                    font-family: $secondary-font;
                    font-weight: bold;

                   

                    &.montant-produit {
                        -webkit-text-stroke: 1px black;
                        width: fit-content;
                        margin-right: 1rem;
                        letter-spacing: 0.2rem;
                    }
                }

                .select-quantity {
                    width: 6rem;
                    display: flex;
                    justify-content: space-evenly;

                    input {
                        width: 1.5rem;
                        text-align: center;
                        border: none;

                        &:focus {
                            outline: none;
                        }
                    }

                    button {
                        width: 100%;
                        border: none;
                        margin: 0.5rem;
                        background-color: white;
                        border-radius: 0.2rem;
                        cursor: pointer;
                        font-size: 2rem;
                        // font-weight: bold;
                        font-family: $secondary-font;

                        &:hover {
                            box-shadow: 0 0 0.3rem rgb(208, 208, 208);
                        }
                    }
                }

                .icon-delete {
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: $pictoDelete;
                    width: 30px;
                    height: 30px;
                    background-size: cover;
                    cursor: pointer;

                    &:hover {
                        transition: 0.3s;
                        transform: scale(1.1);
                    }
                }
            }
        }

        .total-panier {
            width: 100%;
            border-top: 1px solid rgb(214, 214, 214);
            display: flex;
            justify-content: flex-end;
            padding-top: 0.7rem;

            h4 {
                font-family: $primary-font;
            }

            p {
                font-family: $secondary-font;
                font-weight: bold;
                width: 7rem;
                text-align: end;
                -webkit-text-stroke: 1px black;
                letter-spacing: 0.2rem;
            }
        }


        .checkout {
            width: 100%;
            padding: 1rem 0;
            text-align: center;
            margin: 1rem auto;
            color: black;
            text-decoration: none;
            letter-spacing: 0.1rem;
            border-radius: 0.2rem;
            font-family: $primary-font;
            background-color: #634236;
                color: white;
            box-shadow: 0 0 0.4rem rgb(184, 184, 184);

            &:hover {
                background-color: white;
                color: black;
                transition: all 0.2s ease;
            }
        }
    }
}

@keyframes bgmpanier1 {
    from {
        background-color: rgba(0, 0, 0, 0);
    }
    to {
        background-color: rgba(0, 0, 0, 0.404);

    }
}

@keyframes slideinmpanier {
    from {
        transform: translate(100%, 0)
    }
    to {
        transform: translate(0, 0);

    }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
    .fondPanier {
        .modalPanier {
            min-width: 500px;
        }
    }
}

@media screen and (max-width: 600px) {
    .fondPanier {
        .modalPanier {
            width: 100%;
            min-width: 300px;
            padding-left: 1rem;
            padding-right: 2rem;

        }
    }
}

