@import '../variables.scss';

.boxConversation {
    width: 64%;
    min-width: 730px ;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 0.7rem rgb(205, 205, 205);
    background-color: rgb(249, 249, 249);

}

.headerConv {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    padding: 0 2rem;
    box-shadow: 0 0 0.7rem rgb(205, 205, 205);
    z-index: 2;

}

.infos {
    width: fit-content;

    p{
        width: fit-content;
    }
}

.buttonCloture {
    width: fit-content;
    font-family: $primary-font;
                padding: 0.4rem 1.5rem;
                margin: 0 1.5rem;
                border: none;
                cursor: pointer;
                border-radius: 0.8rem;
                box-shadow: 0 0.2rem 0.7rem rgb(159, 159, 159);
                color: rgb(226, 226, 226);
                background-color: #634236;
}
.listMessages {
    width: 100%;
    height: 100%;
    max-height: 400px;
    position: relative;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    overflow-y: auto;
    scroll-behavior: smooth;
    transition: all 1s ease;
    p{
        width: fit-content;
        max-width: 50%;
        background-color: white;
        padding: 1rem;
        border-radius: 1rem;
        box-shadow: 0 0 1rem rgb(223, 223, 223);
    }
}

.rightMessage {
    align-self: flex-end;
}
.leftMessage {
    align-self: flex-start;

    
}
.response {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    textarea {
        width: 90%;
        height: 100%;
        border: none;
        padding: 2rem 1.5rem;
        border-radius: 0.5rem 0 0 0.5rem;
        box-shadow: -0.5rem 0 0.7rem rgb(226, 226, 226);
    }
    button {
        height: 100%;
        padding: 0.5rem;
        background-color: rgb(255, 255, 255);
        border: none;
        border-radius: 0 0.5rem 0.5rem 0;
        box-shadow: inset 0 0 0.7rem rgb(228, 228, 228);
        cursor: pointer;
        z-index: 2;
    }
}
